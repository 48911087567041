import React from 'react'
import Aboutbannercomp from '../components/Aboutbannercomp'
import Countercomp from '../components/Countercomp'
import Aboutuscomp from '../components/Aboutuscomp'

export default function About() {
  return (
    <>
        <Aboutbannercomp />
        <Countercomp />
        <Aboutuscomp />
    </>
  )
}
