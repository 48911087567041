import React from 'react'

export default function Footercomp() {
  return (
    <>
        <div className="container-fluid bg-dark text-body footer mt-5 pt-5 wow fadeIn" data-wow-delay="0.1s">
            <div className="container py-3">
                <div className="row g-5">
                    <div className="col-lg-4 col-md-6">
                        <h5 className="text-white mb-4">Address</h5>
                        <p className="mb-2"><i className="fa fa-map-marker-alt me-3"></i>404 Plot no 69  sicof, midc Satpur Nashik - 422007</p>
                        <p className="mb-2"><i className="fa fa-phone-alt me-3"></i><a className="phoneclr" href="tel:+919763422301">9763422301</a> / <a className="phoneclr" href="tel:+919423906477">9423906477</a></p>
                        <p className="mb-2"><i className="fa fa-envelope me-3"></i><a className="phoneclr" href="mailto:info@rajratnaengineering.com">info@rajratnaengineering.com</a></p>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <h5 className="text-white mb-4">Quick Links</h5>
                        <a className="btn btn-link" href="/home">Home</a>
                        <a className="btn btn-link" href="/about">About Us</a>
                        <a className="btn btn-link" href="/contact">Contact Us</a>
                        <a className="btn btn-link" href="/services">Our Services</a>
                    </div>
                    {/* <div className="col-lg-4 col-md-6">
                        <h5 className="text-white mb-4">Product Gallery</h5>
                        <div className="row g-2">
                            <div className="col-4">
                                <img className="img-fluid rounded" src="assets/img/stamping_block1.jpeg" alt=""/>
                            </div>
                            <div className="col-4">
                                <img className="img-fluid rounded" src="assets/img/stamping_block2.jpeg" alt=""/>
                            </div>
                            <div className="col-4">
                                <img className="img-fluid rounded" src="assets/img/stamping_block3.jpeg" alt=""/>
                            </div>
                            <div className="col-4">
                                <img className="img-fluid rounded" src="assets/img/stamping_block4.jpeg" alt=""/>
                            </div>
                            <div className="col-4">
                                <img className="img-fluid rounded" src="assets/img/stamping_block5.jpeg" alt=""/>
                            </div>
                            <div className="col-4">
                                <img className="img-fluid rounded" src="assets/img/stamping_block6.jpeg" alt=""/>
                            </div>
                        </div>
                    </div> */}
                    <div className="col-lg-4 col-md-6">
                        <h5 className="text-white mb-4">Social Media</h5>
                        <div className="d-flex pt-2">
                            <a className="btn btn-square btn-outline-light btn-social" target="_blank" rel="noopener noreferrer" href="https://instagram.com/rajratnaengineering21?igshid=NGVhN2U2NjQ0Yg=="><i className="fab fa-instagram"></i></a>
                            <a className="btn btn-square btn-outline-light btn-social" target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/profile.php?id=61553979743850&mibextid=ZbWKwL"><i className="fab fa-facebook-f"></i></a>
                            <a className="btn btn-square btn-outline-light btn-social" target="_blank" rel="noopener noreferrer" href="/"><i className="fab fa-youtube"></i></a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="copyright">
                    <div className="row">
                        <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                            &copy; <a href="http://localhost:3000">Rajratna</a>, All Right Reserved.
                        </div>
                        <div className="col-md-6 text-center text-md-end">
                            Developed By <a href="https://logicalat.com/">LogicalAT</a>
                            <br/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <a href="" className="btn btn-lg btn-primary btn-lg-square rounded-circle back-to-top"><i className="bi bi-arrow-up"></i></a>
    </>
  )
}
