import React from 'react';
import { useLocation  } from 'react-router-dom';
import Servicesbannercomp from '../components/Servicesbannercomp';
import Servicesfltcomp from '../components/Servicesfltcomp';

export default function Services() {

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const defaultFilter = queryParams.get('defaultFilter') ?? 'two';

  console.log('Default Filter in Services:', defaultFilter);

  
  
  return (
    <>
        <Servicesbannercomp/>
        <Servicesfltcomp defaultFilter={ defaultFilter }/>
    </>
  )
}
