import React from 'react'
import Slidercomp from '../components/Slidercomp'
import Countercomp from '../components/Countercomp'
import Aboutuscomp from '../components/Aboutuscomp'
import Servicescomp from '../components/Servicescomp'
import Whyuscomp from '../components/Whyuscomp'
import Contactformcomp from '../components/Contactformcomp'

const Home = () => {
  return (
    <>
        <Slidercomp />
        <Countercomp />
        <Aboutuscomp />
        <Whyuscomp />
        <Servicescomp />
        <Contactformcomp />
    </>
  )
}
export default Home;