import React from 'react'
import { useLocation } from 'react-router-dom'

export default function Aboutuscomp() {

    const location = useLocation();

    const shouldHideButton = location.pathname === '/about';
  return (
    <>
        <div className="container-fluid bg-light overflow-hidden px-lg-0">
            <div className="container about px-lg-0">
                <div className="row g-0 mx-lg-0">
                    <div className="col-lg-6 ps-lg-0 wow fadeIn" data-wow-delay="0.1s" style={{ minHeight: '400px' }}>
                        <div className="position-relative h-100">
                            <img className="position-absolute img-fluid w-100 h-100" src="assets/img/aboutimg.jpg" style={{ objectFit: 'cover' }} alt=""/>
                        </div>
                    </div>
                    <div className="col-lg-6 about-text wow fadeIn" data-wow-delay="0.5s">
                        <div className="p-lg-5 pe-lg-0">
                            <h6 className="text-primary">About Us</h6>
                            <p><span> Rajratna engineering</span> established in 2021 Rajratna engineering focuses on providing premium quality complex & delicate Cnc engraving & micro milling job work services to various industries. to give our client’s the ultimate product in terms of fit for purpose, accuracy, durability and consistency, in the fastest possible time, at value for money prices and to generate the highest levels of customer satisfaction</p>
                            {!shouldHideButton && <a href="/about" className="btn btn-primary rounded-pill py-3 px-5 mt-3">Explore More</a>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}
