import React from 'react'

export default function Whyuscomp() {
  return (
    <>
        <div className="container-fluid bg-light overflow-hidden px-lg-0">
            <div className="container feature px-lg-0">
                <div className="row g-0 mx-lg-0">
                    <div className="col-lg-6 py-3 feature-text wow fadeIn" data-wow-delay="0.1s">
                        <div className="p-lg-5 ps-lg-0">
                            <h6 className="text-primary">Why Choose Us!</h6>
                            <h1 className="mb-4">Rajratna engineering established in 2021</h1>
                            <p className="mb-4 pb-2">Every day, in all our activities,  we put the commitment and passion  of those who know that every Creation, even the smallest one, will be very important for those who, tomorrow, will use it to create,  in their turn, A masterpiece.</p>
                            <div className="row g-4">
                                <div className="col-12">
                                    <div className="d-flex align-items-center">
                                        <div className="btn-lg-square bg-primary rounded-circle">
                                            <i className="fa fa-check text-white"></i>
                                        </div>
                                        <div className="ms-4">
                                            <h5 className="mb-0">Our Mission</h5>
                                            <p className="mb-0">Our goal is to deliver exclusive quality and great services at a competitive price.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="d-flex align-items-center">
                                        <div className="btn-lg-square bg-primary rounded-circle">
                                            <i className="fa fa-headphones text-white"></i>
                                        </div>
                                        <div className="ms-4">
                                            <h5 className="mb-0">Our Vision</h5>
                                            <p className="mb-0">To be one of the top most die manufacturers in india and first choice for customers and employees.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 pe-lg-0 wow fadeIn" data-wow-delay="0.5s" style={{ minHeight: '400px' }}>
                        <div className="position-relative h-100">
                            <img className="position-absolute img-fluid w-100 h-100" src="assets/img/whyus.jpg" style={{ objectFit: 'cover' }} alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}