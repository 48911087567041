import React from 'react'
import Contactbannercomp from '../components/Contactbannercomp'
import Contactformcomp from '../components/Contactformcomp'

export default function Contact() {
  return (
    <>
        <Contactbannercomp/>
        <Contactformcomp/>
    </>
  )
}
