import React from 'react'
import { Link, NavLink } from 'react-router-dom'

export default function Headercomp() {

    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      };
    
  return (
    <>
        <div className="container-fluid bg-dark p-0">
            <div className="row gx-0 d-none d-lg-flex">
                <div className="col-lg-7 px-5 text-start">
                    <div className="h-100 d-inline-flex align-items-center">
                        <small className="far fa-clock text-primary me-2"></small>
                        <small className="textclr">Mon - Fri : 09.00 AM - 09.00 PM</small>
                    </div>
                </div>
                <div className="col-lg-5 px-5 text-end">
                    <div className="h-100 d-inline-flex align-items-center me-4">
                        <small className="fa fa-phone-alt text-primary me-2"></small>
                        <small className="textclr"><a className="phoneclr" href="tel:+919763422301">9763422301</a> / <a className="phoneclr" href="tel:+919423906477">9423906477</a></small>
                    </div>
                    <div className="h-100 d-inline-flex align-items-center mx-n2">
                        <a className="btn btn-square btn-link rounded-0" target="_blank" rel="noopener noreferrer" href="https://instagram.com/rajratnaengineering21?igshid=NGVhN2U2NjQ0Yg=="><i className="fab fa-instagram"></i></a>

                        <a className="btn btn-square btn-link rounded-0 border-0 border-end border-secondary" target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/profile.php?id=61553979743850&mibextid=ZbWKwL"><i className="fab fa-facebook-f"></i></a>
                        <a className="btn btn-square btn-link rounded-0 border-0 border-end border-secondary" href=""><i className="fab fa-youtube"></i></a>
                    </div>
                </div>
            </div>
        </div>
        <nav className="navbar navbar-expand-lg bg-white navbar-light sticky-top p-0">
            <a href="/" className="navbar-brand d-flex align-items-center border-end px-4 px-lg-5">
                <img style={{ height:'70px' }} src="assets/img/rajratna.png" alt=""/>
            </a>
            <div className="collapse navbar-collapse" id="navbarCollapse">
                <div className="navbar-nav ms-auto p-4 p-lg-0">
                    <NavLink to="/" className="nav-item nav-link" onClick={scrollToTop}>Home</NavLink>
                    <NavLink to="/about" className="nav-item nav-link" onClick={scrollToTop}>About</NavLink>
                    <NavLink to="/services?defaultFilter=two" className="nav-item nav-link" onClick={scrollToTop}>Services</NavLink>
                    {/* <NavLink to="contact" className="nav-item nav-link">Contact</NavLink> */}
                </div>
                <Link to="/contact" class="btn btn-primary rounded-0 py-4 px-lg-5 d-none d-lg-block" onClick={scrollToTop}>Contact<i class="fa fa-arrow-right ms-3"></i></Link>
            </div>
        </nav>
    </>
  )
}