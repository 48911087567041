import { Routes, Route } from 'react-router-dom';
import './App.css';
import Footercomp from './components/Footercomp';
import Headercomp from './components/Headercomp';
import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';
import Projects from './pages/Projects';
import Contact from './pages/Contact';

function App() {
  return (
    <>
      <Headercomp />
      <Routes>
        <Route path="/" element={ <Home/> }/>
        <Route path="/about" element={ <About/> } />
        <Route path="/services/:defaultFilter?" element={<Services />} />
        <Route path="/projects" element={ <Projects/> } />
        <Route path="/contact" element={ <Contact /> } />
      </Routes>
      <Footercomp />
    </>
  );
}

export default App;
