import React from 'react'

export default function Countercomp() {
  return (
    <>
        <div className="container-xxl mt-4 pb-5">
            <div className="container">
                <div className="row g-5">
                    <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.1s">
                        <div className="d-flex align-items-center mb-4">
                            <div className="btn-lg-square bg-primary rounded-circle me-3">
                                <i className="fa fa-users text-white"></i>
                            </div>
                            <h1 className="mb-0" data-toggle="counter-up">340</h1>
                        </div>
                        <h5 className="mb-3">Happy Customers</h5>
                        <span>Precision Craftsmanship, Delighting Customers Every Step of the Way</span>
                    </div>
                    <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.3s">
                        <div className="d-flex align-items-center mb-4">
                            <div className="btn-lg-square bg-primary rounded-circle me-3">
                                <i className="fa fa-check text-white"></i>
                            </div>
                            <h1 className="mb-0" data-toggle="counter-up">45 +</h1>
                        </div>
                        <h5 className="mb-3">Total Products</h5>
                        <span>Engineering Perfection, Infinite Solutions for Your Every Need.</span>
                    </div>
                    <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.5s">
                        <div className="d-flex align-items-center mb-4">
                            <div className="btn-lg-square bg-primary rounded-circle me-3">
                                <i className="fa fa-award text-white"></i>
                            </div>
                            <h1 className="mb-0" data-toggle="counter-up"> 7 </h1>
                        </div>
                        <h5 className="mb-3">Awards Win</h5>
                        <span> Where Excellence Wins Recognition</span>
                    </div>
                    <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.7s">
                        <div className="d-flex align-items-center mb-4">
                            <div className="btn-lg-square bg-primary rounded-circle me-3">
                                <i className="fa fa-users-cog text-white"></i>
                            </div>
                            <h1 className="mb-0" data-toggle="counter-up">15</h1>
                        </div>
                        <h5 className="mb-3">Expert Workers</h5>
                        <span>Expert Workers Transform Visions into Reality.</span>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}
