import React from 'react';
import OwlCarousel from 'react-owl-carousel';

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from 'react-router-dom';

const options = {
  items: 1,
  loop: true,
  autoplay: true,
  autoplayTimeout: 30000,
};

const items = [
  // {
  //   title: 'Rajratna engineering',
  //   description: 'focuses on 3 axis & 4 axis premium Quality cnc engraving & micro milling.',
  //   imageUrl: 'assets/img/banner5.jpg',
  // },
  // {
  //   title: 'Rajratna engineering',
  //   description: 'focuses on 3 axis & 4 axis premium Quality cnc engraving & micro milling.',
  //   imageUrl: 'assets/img/banner1.jpg',
  // },
  // {
  //   title: 'Rajratna engineering',
  //   description: 'focuses on 3 axis & 4 axis premium Quality cnc engraving & micro milling.',
  //   imageUrl: 'assets/img/banner3.jpg',
  // },
  {
    title: 'Rajratna engineering',
    description: 'focuses on 3 axis & 4 axis premium Quality cnc engraving & micro milling.',
    imageUrl: 'assets/img/banner7.jpg',
  },
  
];

function Slidercomp() {
  return (
    <OwlCarousel className="owl-theme" {...options}>
      {items.map((item, index) => (
        <div className="item" key={index}>
          <img src={item.imageUrl} alt={item.title} />
          <div className="carousel-content">
            <h1 className="display-2 text-white">{item.title}</h1>
            <p className="fs-5 fw-medium text-white mb-4 pb-3">{item.description}</p>
            <Link to="/contact" className="btn btn-primary rounded-pill py-3 px-5">Contact US</Link>
          </div>
        </div>
      ))}
    </OwlCarousel>
  );
}

export default Slidercomp;