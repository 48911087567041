import React, { useEffect, useState } from 'react';
import Isotope from 'isotope-layout';

export default function Servicesfltcomp({defaultFilter}) {
    
    const [activeFilter, setActiveFilter] = useState(defaultFilter);

    const portfolioItems = [
        {
            id: 1,
            imageSrc: "assets/img/axis41.jpg",
            filterClass: "eight",
            altText: "Rotary stamping block",
        },
        {
            id: 2,
            imageSrc: "assets/img/stamping_block1.jpeg",
            filterClass: "two",
            altText: "Flat hot foil stamping block",
        },
        {
            id: 3,
            imageSrc: "assets/img/stamping_block2.jpeg",
            filterClass: "two",
            altText: "Flat hot foil stamping block",
        },
        {
            id: 4,
            imageSrc: "assets/img/stamping_block3.jpeg",
            filterClass: "two",
            altText: "Flat hot foil stamping block",
        },
        {
            id: 5,
            imageSrc: "assets/img/stamping_block4.jpeg",
            filterClass: "two",
            altText: "Flat hot foil stamping block",
        },
        {
            id: 6,
            imageSrc: "assets/img/stamping_block5.jpeg",
            filterClass: "two",
            altText: "Flat hot foil stamping block",
        },
        {
            id: 7,
            imageSrc: "assets/img/stamping_block6.jpeg",
            filterClass: "two",
            altText: "Flat hot foil stamping block",
        },
        {
            id: 9,
            imageSrc: "assets/img/silicon_dies1.jpeg",
            filterClass: "seven",
            altText: "Rubber / silicon dies",
        },
        {
            id: 10,
            imageSrc: "assets/img/micro_embossing1.jpeg",
            filterClass: "nine",
            altText: "Micro/nanno embossing block",
        },
        {
            id: 11,
            imageSrc: "assets/img/micro_embossing2.jpeg",
            filterClass: "nine",
            altText: "Micro/nanno embossing block",
        },
        {
            id: 12,
            imageSrc: "assets/img/micro_embossing3.jpeg",
            filterClass: "nine",
            altText: "Micro/nanno embossing block",
        },
        {
            id: 13,
            imageSrc: "assets/img/micro_embossing4.jpeg",
            filterClass: "nine",
            altText: "Micro/nanno embossing block",
        },
        {
            id: 14,
            imageSrc: "assets/img/micro_embossing5.jpeg",
            filterClass: "nine",
            altText: "Micro/nanno embossing block",
        },
        {
            id: 15,
            imageSrc: "assets/img/micro_embossing6.jpeg",
            filterClass: "nine",
            altText: "Micro/nanno embossing block",
        },
        {
            id: 16,
            imageSrc: "assets/img/braille_block1.jpeg",
            filterClass: "ten",
            altText: "Braille Block",
        },
        {
            id: 18,
            imageSrc: "assets/img/braille_block3.jpeg",
            filterClass: "ten",
            altText: "Braille Block",
        },
        {
            id: 19,
            imageSrc: "assets/img/braille_block4.jpeg",
            filterClass: "ten",
            altText: "Braille Block",
        },
        {
            id: 20,
            imageSrc: "assets/img/barmat_die1.jpeg",
            filterClass: "eleven",
            altText: "Barmat die",
        },
        {
            id: 21,
            imageSrc: "assets/img/barmat_die2.jpeg",
            filterClass: "eleven",
            altText: "Barmat die",
        },
        {
            id: 22,
            imageSrc: "assets/img/barmat_die3.jpeg",
            filterClass: "eleven",
            altText: "Barmat die",
        },
        {
            id: 23,
            imageSrc: "assets/img/plastic_molding1.jpeg",
            filterClass: "fifteen",
            altText: "Plastic molding dies",
        },
        {
            id: 24,
            imageSrc: "assets/img/axis42.jpg",
            filterClass: "eight",
            altText: "Rotary stamping block",
        },
        {
            id: 8,
            imageSrc: "assets/img/axis43.jpg",
            filterClass: "eight",
            altText: "Rotary stamping block",
        },
        {
            id: 25,
            imageSrc: "assets/img/barmatmold2.jpg",
            filterClass: "eleven",
            altText: "Barmat die",
        },
        {
            id: 26,
            imageSrc: "assets/img/barmatmold3.jpg",
            filterClass: "eleven",
            altText: "Barmat die",
        },
        {
            id: 27,
            imageSrc: "assets/img/brailledies1.jpg",
            filterClass: "ten",
            altText: "Braille Block",
        },
        {
            id: 28,
            imageSrc: "assets/img/brailledies2.jpg",
            filterClass: "ten",
            altText: "Braille Block",
        },
        {
            id: 17,
            imageSrc: "assets/img/coiningdie1.jpg",
            filterClass: "thirteen",
            altText: "Coining Dies",
        },
        {
            id: 29,
            imageSrc: "assets/img/coiningdie2.jpg",
            filterClass: "thirteen",
            altText: "Coining Dies",
        },
        {
            id: 30,
            imageSrc: "assets/img/combinedie1.jpg",
            filterClass: "eighteen",
            altText: "Combine Dies",
        },
        {
            id: 31,
            imageSrc: "assets/img/combinedie2.jpg",
            filterClass: "eighteen",
            altText: "Combine Dies",
        },
        {
            id: 32,
            imageSrc: "assets/img/coppere1.jpg",
            filterClass: "nineteen",
            altText: "Copper / Graphite electrode",
        },
        {
            id: 33,
            imageSrc: "assets/img/coppere6.jpg",
            filterClass: "nineteen",
            altText: "Copper / Graphite electrode",
        },
        {
            id: 34,
            imageSrc: "assets/img/coppere3.jpg",
            filterClass: "nineteen",
            altText: "Copper / Graphite electrode",
        },
        {
            id: 35,
            imageSrc: "assets/img/coppere4.jpg",
            filterClass: "nineteen",
            altText: "Copper / Graphite electrode",
        },
        {
            id: 36,
            imageSrc: "assets/img/coppere5.jpg",
            filterClass: "nineteen",
            altText: "Copper / Graphite electrode",
        },
        {
            id: 37,
            imageSrc: "assets/img/coppere2.jpg",
            filterClass: "nineteen",
            altText: "Copper / Graphite electrode",
        },
        {
            id: 38,
            imageSrc: "assets/img/graphitemold1.jpg",
            filterClass: "twenty",
            altText: "Graphite Mold",
        },
        {
            id: 39,
            imageSrc: "assets/img/graphitemold2.jpg",
            filterClass: "twenty",
            altText: "Graphite Mold",
        },
        {
            id: 40,
            imageSrc: "assets/img/graphitemold3.jpg",
            filterClass: "twenty",
            altText: "Graphite Mold",
        },
        {
            id: 41,
            imageSrc: "assets/img/hotfoilstamping5.jpg",
            filterClass: "two",
            altText: "Flat hot foil stamping block",
        },
        {
            id: 42,
            imageSrc: "assets/img/jwellarydies1.jpg",
            filterClass: "fourteen",
            altText: "Jwellary Dies",
        },
        {
            id: 43,
            imageSrc: "assets/img/jwellarydies2.jpg",
            filterClass: "fourteen",
            altText: "Jwellary Dies",
        },
        {
            id: 44,
            imageSrc: "assets/img/jwellarydies3.jpg",
            filterClass: "fourteen",
            altText: "Jwellary Dies",
        },
        {
            id: 45,
            imageSrc: "assets/img/jwellarydies4.jpg",
            filterClass: "fourteen",
            altText: "Jwellary Dies",
        },
        {
            id: 46,
            imageSrc: "assets/img/jwellarydies5.jpg",
            filterClass: "fourteen",
            altText: "Jwellary Dies",
        },
        {
            id: 47,
            imageSrc: "assets/img/jwellarydies6.jpg",
            filterClass: "fourteen",
            altText: "Jwellary Dies",
        },
        {
            id: 48,
            imageSrc: "assets/img/magnesiumdies1.jpg",
            filterClass: "twentyone",
            altText: "Magnesium Dies",
        },
        {
            id: 49,
            imageSrc: "assets/img/magnesiumdies2.jpg",
            filterClass: "twentyone",
            altText: "Magnesium Dies",
        },
        {
            id: 50,
            imageSrc: "assets/img/magnesiumdies3.jpg",
            filterClass: "twentyone",
            altText: "Magnesium Dies",
        },
        {
            id: 51,
            imageSrc: "assets/img/magnesiumdies4.jpg",
            filterClass: "twentyone",
            altText: "Magnesium Dies",
        },
        {
            id: 52,
            imageSrc: "assets/img/magnesiumdies5.jpg",
            filterClass: "twentyone",
            altText: "Magnesium Dies",
        },
        {
            id: 53,
            imageSrc: "assets/img/membranekeydie1.jpg",
            filterClass: "twentytwo",
            altText: "Membranekey Dies",
        },
        {
            id: 54,
            imageSrc: "assets/img/membranekeydie2.jpg",
            filterClass: "twentytwo",
            altText: "Membranekey Dies",
        },
        {
            id: 55,
            imageSrc: "assets/img/vastudoshnash1.jpg",
            filterClass: "twentythree",
            altText: "Vastu Doshnashak Yantra",
        },
        {
            id: 56,
            imageSrc: "assets/img/vastudoshnash2.jpg",
            filterClass: "twentythree",
            altText: "Vastu Doshnashak Yantra",
        },


        


        

        
        
    ];

    const filters = [
        
        {
            id: 2,
            className: "two",
            name: "Flat hot foil stamping block",
        },
        {
            id: 3,
            className: "three",
            name: "Embbossing / Debossing block",
        },
        {
            id: 4,
            className: "four",
            name: "Embossing with foiling block",
        },
        {
            id: 5,
            className: "five",
            name: "3D Embossing / Debossing block",
        },
        {
            id: 6,
            className: "six",
            name: "Holographic foiling block",
        },
        {
            id: 7,
            className: "seven",
            name: "Rubber / Silicon dies",
        },
        {
            id: 8,
            className: "eight",
            name: "Rotary stamping block",
        },
        {
            id: 9,
            className: "nine",
            name: "Micro / Nanno embossing block",
        },
        {
            id: 10,
            className: "ten",
            name: "Braille block",
        },
        {
            id: 11,
            className: "eleven",
            name: "Barmat die",
        },
        {
            id: 12,
            className: "tweel",
            name: "Soap mold",
        },
        {
            id: 13,
            className: "thirteen",
            name: "Coining dies",
        },
        {
            id: 14,
            className: "fourteen",
            name: "Jwellary dies",
        },
        {
            id: 15,
            className: "fifteen",
            name: "Plastic molding dies",
        },
        {
            id: 16,
            className: "sixteen",
            name: "Leaser engraving",
        },
        {
            id: 17,
            className: "seventeen",
            name: "Eatching dies",
        },
        {
            id: 18,
            className: "eighteen",
            name: "Combine Dies",
        },
        {
            id: 19,
            className: "nineteen",
            name: "Copper / Graphite electrode",
        },
        {
            id: 20,
            className: "twenty",
            name: "Graphite Mold",
        },
        {
            id: 21,
            className: "twentyone",
            name: "Magnesium Dies",
        },
        {
            id: 22,
            className: "twentytwo",
            name: "Membranekey Dies",
        },
        {
            id: 23,
            className: "twentythree",
            name: "Vastu Doshnashak Yantra",
        },
        


        

        
        
    ];
    
  useEffect(() => {
    // Initialize Isotope
    const grid = document.querySelector('.portfolio-container');
    const iso = new Isotope(grid, {
      itemSelector: '.portfolio-item',
      layoutMode: 'masonry',
        masonry: {
            columnWidth: '.portfolio-item',
            gutter: 0, // Adjust gutter as needed
        },
        filter: `.${activeFilter}`,
    });

    // Filter buttons
    const filterButtons = document.querySelectorAll('#portfolio-flters li');
    filterButtons.forEach((button) => {
      button.addEventListener('click', () => {
        const filterValue = button.getAttribute('data-filter');
        iso.arrange({ filter: filterValue });

        // Set the active filter and remove 'active' class from all buttons
        setActiveFilter(filterValue.replace('.', ''));
        filterButtons.forEach((btn) => btn.classList.remove('active'));
        button.classList.add('active');
      });
    });
  }, [activeFilter]);
  return (
    <>
        <div className="container-xxl">
            <div className="container">
                <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: '800px' }}>
                    <h6 className="text-primary">Our Products</h6>
                    <h1 className="mb-4">We Are specializes in CNC engraving and micro milling services</h1>
                </div>
                <div className="row mt-n2 wow fadeInUp" data-wow-delay="0.3s">
                    <div className="col-12 text-center">
                        <ul className="list-inline mb-5" id="portfolio-flters">
                        {filters.map((filter) => (
                            <li key={filter.className} className={`mx-2 ${activeFilter === filter.className ? 'active' : ''}`} data-filter={`.${filter.className}`} > {filter.name} </li>
                        ))}
                        </ul>
                    </div>
                </div>
                <div className="row g-4 portfolio-container wow fadeInUp clearfix" data-wow-delay="0.5s">
                {portfolioItems.map((item, index) => (
                    <div key={index} className={`col-lg-4 col-md-6 portfolio-item ${item.filterClass}`}>
                        <img className="img-fluid" src={item.imageSrc} alt={item.altText} />
                        <div className="portfolio-btn">
                            <a className="btn btn-lg-square btn-outline-light rounded-circle mx-1" href={item.imageSrc} data-lightbox="portfolio" > <i className="fa fa-eye"></i> </a>
                        </div>
                    </div>
                ))}
                </div>
            </div>
        </div>
    </>
  );
}