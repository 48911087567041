import React from 'react'
import Projectsbannercomp from '../components/Projectsbannercomp'
import Projectscomp from '../components/Projectscomp'
import Quotecomp from '../components/Quotecomp'

export default function Projects() {
  return (
    <>
        <Projectsbannercomp />
        <Projectscomp />
        <Quotecomp />
    </>
  )
}
