import React, { useState } from 'react';

export default function Servicescomp() {

    const [isHovered, setHovered] = useState(false);

    const handleMouseOver = () => {
        setHovered(true);
    };

    const handleMouseOut = () => {
        setHovered(false);
    };

    const handleClick = (defaultFilter) => {
        window.location.href = `/services?defaultFilter=${defaultFilter}`;
    };

    return (
        <>
            <div className="container-xxl pt-5">
                <div className="container">
                    <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: '800px' }}>
                        <h6 className="text-primary">Our Products</h6>
                        <h1 className="mb-6">We Are specializes in CNC engraving and micro milling services</h1>
                    </div>
                </div>
            </div>
            <div className="container-xxl">
                <div className="container">
                    <div className="row">
                        
                        <div className={`col-md-6 col-lg-3 wow fadeIn ${isHovered ? 'pointer-on-hover' : ''}`} data-wow-delay="0.1s" onClick={() => handleClick('two') } onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} >
                            <div className="d-flex align-items-center">
                                <div className="btn-lg-square bg-primary rounded-circle me-3">
                                    <i className="fa fa-check text-white"></i>
                                </div>
                                <h5 className="mb-3">Flat hot foil stamping block</h5>
                            </div>
                        </div>
                        <div className={`col-md-6 col-lg-3 wow fadeIn ${isHovered ? 'pointer-on-hover' : ''}`} data-wow-delay="0.3s" onClick={() => handleClick('three') } onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} >
                            <div className="d-flex align-items-center">
                                <div className="btn-lg-square bg-primary rounded-circle me-3">
                                    <i className="fa fa-check text-white"></i>
                                </div>
                                <h5 className="mb-3">Embbossing / Debossing block</h5>
                            </div>
                        </div>
                        {/* <div className={`col-md-6 col-lg-3 wow fadeIn ${isHovered ? 'pointer-on-hover' : ''}`} data-wow-delay="0.5s" onClick={ () => handleClick('four') } onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} >
                            <div className="d-flex align-items-center">
                                <div className="btn-lg-square bg-primary rounded-circle me-3">
                                    <i className="fa fa-check text-white"></i>
                                </div>
                                <h5 className="mb-3">Embossing with foiling block</h5>
                            </div>
                        </div> */}
                        <div className={`col-md-6 col-lg-3 wow fadeIn ${isHovered ? 'pointer-on-hover' : ''}`} data-wow-delay="0.7s" onClick={ () => handleClick('five') } onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} >
                            <div className="d-flex align-items-center">
                                <div className="btn-lg-square bg-primary rounded-circle me-3">
                                    <i className="fa fa-check text-white"></i>
                                </div>
                                <h5 className="mb-3">Embossing with foiling block</h5>
                            </div>
                        </div>
                        <div className={`col-md-6 col-lg-3 wow fadeIn ${isHovered ? 'pointer-on-hover' : ''}`} data-wow-delay="0.1s" onClick={() => handleClick('six') } onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} >
                            <div className="d-flex align-items-center">
                                <div className="btn-lg-square bg-primary rounded-circle me-3">
                                    <i className="fa fa-check text-white"></i>
                                </div>
                                <h5 className="mb-3">Holographic foiling block</h5>
                            </div>
                        </div>
                        <div className={`col-md-6 col-lg-3 wow fadeIn ${isHovered ? 'pointer-on-hover' : ''}`} data-wow-delay="0.3s" onClick={() => handleClick('seven') } onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} >
                            <div className="d-flex align-items-center">
                                <div className="btn-lg-square bg-primary rounded-circle me-3">
                                    <i className="fa fa-check text-white"></i>
                                </div>
                                <h5 className="mb-3">Rubber / silicon dies</h5>
                            </div>
                        </div>
                        <div className={`col-md-6 col-lg-3 wow fadeIn ${isHovered ? 'pointer-on-hover' : ''}`} data-wow-delay="0.5s" onClick={() => handleClick('eight') } onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} >
                            <div className="d-flex align-items-center">
                                <div className="btn-lg-square bg-primary rounded-circle me-3">
                                    <i className="fa fa-check text-white"></i>
                                </div>
                                <h5 className="mb-3">Rotary stamping block</h5>
                            </div>
                        </div>
                        <div className={`col-md-6 col-lg-3 wow fadeIn ${isHovered ? 'pointer-on-hover' : ''}`} data-wow-delay="0.7s" onClick={() => handleClick('nine') } onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} >
                            <div className="d-flex align-items-center">
                                <div className="btn-lg-square bg-primary rounded-circle me-3">
                                    <i className="fa fa-check text-white"></i>
                                </div>
                                <h5 className="mb-3">Micro/nanno embossing block</h5>
                            </div>
                        </div>
                        <div className={`col-md-6 col-lg-3 wow fadeIn ${isHovered ? 'pointer-on-hover' : ''}`} data-wow-delay="0.1s" onClick={() => handleClick('ten') } onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} >
                            <div className="d-flex align-items-center">
                                <div className="btn-lg-square bg-primary rounded-circle me-3">
                                    <i className="fa fa-check text-white"></i>
                                </div>
                                <h5 className="mb-3">Braille block</h5>
                            </div>
                        </div>
                        <div className={`col-md-6 col-lg-3 wow fadeIn ${isHovered ? 'pointer-on-hover' : ''}`} data-wow-delay="0.3s" onClick={() => handleClick('eleven') } onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} >
                            <div className="d-flex align-items-center">
                                <div className="btn-lg-square bg-primary rounded-circle me-3">
                                    <i className="fa fa-check text-white"></i>
                                </div>
                                <h5 className="mb-3">Barmat die</h5>
                            </div>
                        </div>
                        <div className={`col-md-6 col-lg-3 wow fadeIn ${isHovered ? 'pointer-on-hover' : ''}`} data-wow-delay="0.5s" onClick={() => handleClick('tweel') } onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} >
                            <div className="d-flex align-items-center">
                                <div className="btn-lg-square bg-primary rounded-circle me-3">
                                    <i className="fa fa-check text-white"></i>
                                </div>
                                <h5 className="mb-3">Soap mold</h5>
                            </div>
                        </div>
                        <div className={`col-md-6 col-lg-3 wow fadeIn ${isHovered ? 'pointer-on-hover' : ''}`} data-wow-delay="0.7s" onClick={() => handleClick('thirteen') } onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} >
                            <div className="d-flex align-items-center">
                                <div className="btn-lg-square bg-primary rounded-circle me-3">
                                    <i className="fa fa-check text-white"></i>
                                </div>
                                <h5 className="mb-3">Coining dies</h5>
                            </div>
                        </div>
                        <div className={`col-md-6 col-lg-3 wow fadeIn ${isHovered ? 'pointer-on-hover' : ''}`} data-wow-delay="0.1s" onClick={() => handleClick('fourteen') } onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} >
                            <div className="d-flex align-items-center">
                                <div className="btn-lg-square bg-primary rounded-circle me-3">
                                    <i className="fa fa-check text-white"></i>
                                </div>
                                <h5 className="mb-3">Jwellary dies</h5>
                            </div>
                        </div>
                        <div className={`col-md-6 col-lg-3 wow fadeIn ${isHovered ? 'pointer-on-hover' : ''}`} data-wow-delay="0.3s" onClick={() => handleClick('fifteen') } onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} >
                            <div className="d-flex align-items-center">
                                <div className="btn-lg-square bg-primary rounded-circle me-3">
                                    <i className="fa fa-check text-white"></i>
                                </div>
                                <h5 className="mb-3">Plastic molding dies</h5>
                            </div>
                        </div>
                        <div className={`col-md-6 col-lg-3 wow fadeIn ${isHovered ? 'pointer-on-hover' : ''}`} data-wow-delay="0.5s" onClick={() => handleClick('sixteen') } onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} >
                            <div className="d-flex align-items-center">
                                <div className="btn-lg-square bg-primary rounded-circle me-3">
                                    <i className="fa fa-check text-white"></i>
                                </div>
                                <h5 className="mb-3">Leaser engraving</h5>
                            </div>
                        </div>
                        <div className={`col-md-6 col-lg-3 wow fadeIn ${isHovered ? 'pointer-on-hover' : ''}`} data-wow-delay="0.5s" onClick={() => handleClick('eighteen') } onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} >
                            <div className="d-flex align-items-center">
                                <div className="btn-lg-square bg-primary rounded-circle me-3">
                                    <i className="fa fa-check text-white"></i>
                                </div>
                                <h5 className="mb-3">Combine Dies</h5>
                            </div>
                        </div>
                        <div className={`col-md-6 col-lg-3 wow fadeIn ${isHovered ? 'pointer-on-hover' : ''}`} data-wow-delay="0.5s" onClick={() => handleClick('nineteen') } onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} >
                            <div className="d-flex align-items-center">
                                <div className="btn-lg-square bg-primary rounded-circle me-3">
                                    <i className="fa fa-check text-white"></i>
                                </div>
                                <h5 className="mb-3">Copper / Graphite electrode</h5>
                            </div>
                        </div>
                        
                        <div className={`col-md-6 col-lg-3 wow fadeIn ${isHovered ? 'pointer-on-hover' : ''}`} data-wow-delay="0.5s" onClick={() => handleClick('twenty') } onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} >
                            <div className="d-flex align-items-center">
                                <div className="btn-lg-square bg-primary rounded-circle me-3">
                                    <i className="fa fa-check text-white"></i>
                                </div>
                                <h5 className="mb-3">Graphite Mold</h5>
                            </div>
                        </div>
                        <div className={`col-md-6 col-lg-3 wow fadeIn ${isHovered ? 'pointer-on-hover' : ''}`} data-wow-delay="0.5s" onClick={() => handleClick('twentyone') } onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} >
                            <div className="d-flex align-items-center">
                                <div className="btn-lg-square bg-primary rounded-circle me-3">
                                    <i className="fa fa-check text-white"></i>
                                </div>
                                <h5 className="mb-3">Magnesium Dies</h5>
                            </div>
                        </div>
                        <div className={`col-md-6 col-lg-3 wow fadeIn ${isHovered ? 'pointer-on-hover' : ''}`} data-wow-delay="0.5s" onClick={() => handleClick('twentytwo') } onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} >
                            <div className="d-flex align-items-center">
                                <div className="btn-lg-square bg-primary rounded-circle me-3">
                                    <i className="fa fa-check text-white"></i>
                                </div>
                                <h5 className="mb-3">Membranekey Dies</h5>
                            </div>
                        </div>
                        <div className={`col-md-6 col-lg-3 wow fadeIn ${isHovered ? 'pointer-on-hover' : ''}`} data-wow-delay="0.5s" onClick={() => handleClick('twentythree') } onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} >
                            <div className="d-flex align-items-center">
                                <div className="btn-lg-square bg-primary rounded-circle me-3">
                                    <i className="fa fa-check text-white"></i>
                                </div>
                                <h5 className="mb-3">Vastu Doshnashak Yantra</h5>
                            </div>
                        </div>
                        <div className={`col-md-6 col-lg-3 wow fadeIn ${isHovered ? 'pointer-on-hover' : ''}`} data-wow-delay="0.7s" onClick={() => handleClick('seventeen') } onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} >
                            <div className="d-flex align-items-center">
                                <div className="btn-lg-square bg-primary rounded-circle me-3">
                                    <i className="fa fa-check text-white"></i>
                                </div>
                                <h5 className="mb-3">Eatching dies</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}