import emailjs from '@emailjs/browser';
import React, { useRef } from 'react';

export default function Contactformcomp() {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
        
        emailjs.sendForm('service_9pagf36', 'template_dqset8i', form.current, 'ELMKLdHBYy0GgjQV5')
            .then((result) => {
                alert("Mail Send successfuly");
                window.location.reload();
            }, (error) => {
                alert("Failed to send mail");
            });
    };
   
  return (
    <>
        <div className="container-fluid bg-light overflow-hidden px-lg-0" style={{ margin: '3rem 0' }}>
            <div className="container contact px-lg-0">
                <div className="row g-0 mx-lg-0">
                    <div className="col-lg-6 contact-text wow fadeIn" data-wow-delay="0.5s">
                        <div className="p-lg-5 ps-lg-0">
                            <h6 className="text-primary">Contact Us</h6>
                            <h1 className="mb-4">Feel Free To Contact Us</h1>
                            <form ref={form} onSubmit={sendEmail} >
                                <div className="row g-3">
                                    <div className="col-md-6">
                                        <div className="form-floating">
                                            <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" required/>
                                            <label htmlFor="name">Your Name</label>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-floating">
                                            <input type="email" name="email" className="form-control" id="email" placeholder="Your Email" required/>
                                            <label htmlFor="email">Your Email</label>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-floating">
                                            <input type="text" name="subject" className="form-control" id="subject" placeholder="Subject" required/>
                                            <label htmlFor="subject">Subject</label>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-floating">
                                            <textarea className="form-control" name="message" placeholder="Leave a message here" id="message" style={{ height: '100px' }}></textarea>
                                            <label htmlFor="message">Message</label>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <button className="btn btn-primary rounded-pill py-3 px-5" type="submit">Submit</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-lg-6 pe-lg-0" style={{ minHeight: '400px' }}>
                        <div className="position-relative h-100">
                            <iframe className="position-absolute w-100 h-100" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3129.2925628122416!2d73.728784!3d19.996222!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bddec61c528d577%3A0x5012df8a64319ae!2sSICOF%20-%20MIDC%20Industrial%20Complex!5e1!3m2!1sen!2sin!4v1700565844524!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" title='Map' ></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}